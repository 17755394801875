import React from "react"

import Layout from "../../components/Layout"
import Grid from "../../components/Grid"
import { Link } from "gatsby"
import css from "../setup.module.scss"
import fontType from "../../helpers/fontType.module.scss"

import TRACKINGCODE_EMAIL from "../../assets/images/setup/setup-trackcode-email.png"

const metadata = {
  title: "Connect any ecommerce store to ReferralCandy",
}

const StandaloneSetup = (props) => {
  return (
    <Layout metadata={metadata} pathname={props.location.pathname}>
      <Grid className={css.container} centeredClassName={css.centered}>
        <h1 className={css.center}>
          Set Up ReferralCandy for your ecommerce store
        </h1>
        <p className={fontType.b1}>
          Step 1: Setting up your ReferralCandy Account
        </p>
        <ol type="1">
          <li>
            Head over to the{" "}
            <a href="https://my.referralcandy.com/signup#otherplatforms">
              sign up page
            </a>{" "}
            to register for a new ReferralCandy account.
          </li>
          <li>
            Go through the account setup wizard. At the integration step, note
            the tracking code and email address for your referral program. You
            will need them for the next two parts.
          </li>
          <br />
          <br />
          <img src={TRACKINGCODE_EMAIL} alt="Tracking Code Email" />
          <li>Complete the rest of the setup wizard.</li>
        </ol>
        <p className={fontType.b1}>Step 2: Add our Tracking Code</p>
        <p>
          In order for us to run and analyze your campaign, you need to place a
          snippet of code on the purchase success page of your site.
          ReferralCandy generates the code, simply copy and paste the code.
        </p>
        <p className={fontType.b1}>
          Step 3: Sending invoice emails to ReferralCandy
        </p>
        <p>
          Copy us on the invoice email sent to the customer each time you make a
          sale. (Customer information NDA available upon{" "}
          <a href="https://help.referralcandy.com/support/tickets/new">
            request
          </a>
          ).
        </p>
        <p>
          If forwarding invoices is not an available option for your store, you
          can use one of our{" "}
          <a href="https://help.referralcandy.com/support/solutions/articles/47001056277-integration-overview-different-ways-to-integrate-your-store-with-referralcandy">
            developer integration options
          </a>{" "}
          to provide us with your store’s order information.
        </p>
        <h3>
          Using a different platform? <Link to="/setup">View all guides</Link>
        </h3>
      </Grid>
    </Layout>
  )
}

export default StandaloneSetup
